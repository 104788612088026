<template>
	<div style="position: relative;height: calc(100vh - 135px);">
		<el-row :gutter="20">
			<el-col :span="20">
				<div class="grid-content bg-purple">
					<div class="steps-tip first-tip">选择目标店铺</div>
				</div>
			</el-col>
		</el-row>
		<div>
			<el-row :gutter="20">
				<el-col :span="4">
					<el-select v-model="value" placeholder="请选择">
						<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
						</el-option>
					</el-select>
				</el-col>
				<el-col :span="6">
					<el-input placeholder="搜索店铺名称/ID（多个以,分隔）" v-model="keywords1" class="input-with-select">
						<el-button @click="onsearch" slot="append" icon="el-icon-search"></el-button>
					</el-input>
				</el-col>
			</el-row>
			<el-table height="calc(100vh - 300px)" border ref="multipleTable" :data="shoplist" tooltip-effect="dark"
				style="width: 100%;margin-top: 10px;" @selection-change="shopSelectionChange">
				<el-table-column type="selection" width="55">
				</el-table-column>
				<el-table-column prop="type" label="渠道" width="120">
					<template slot-scope="scope">{{ scope.row.type==1?'饿了么外卖':'饿了么零售' }}</template>
				</el-table-column>
				<el-table-column prop="shopId" label="ID" width="120">
				</el-table-column>
				<el-table-column prop="name" label="店铺名称" show-overflow-tooltip>
				</el-table-column>
			</el-table>
		</div>
		<el-row :gutter="24" style="position: absolute;bottom: 0;width: 100%;">
			<el-col :span="19" style="height: 50px;display: flex;align-items: center;">
			</el-col>
			<el-col :span="5" style="height: 50px;display: flex;align-items: center;">
				<div style="text-align: right;margin-top: 10px;">
					<el-button @click="quxiao" round>取消</el-button>
					<el-button @click="tijiao" type="primary" round>确认提交</el-button>
				</div>
			</el-col>
		</el-row>
	</div>

</template>

<script>
	import axios from 'axios';
	export default {
		data() {
			return {
				keywords1: "",
				shoplist: [],
				tableData1: [],
				options: [{
					value: '选项1',
					label: '全部渠道'
				}, {
					value: '选项2',
					label: '饿了么外卖'
				}, {
					value: '选项3',
					label: '饿了么零售'
				}],
				value: '选项1',
				select: '',
				shop_mul_sel: [],
				selnum: 0,
				type: ""
			}
		},
		created() {
			this.getshoplist();
			console.log()
		},
		computed: {},
		methods: {
			tijiao() {
				if (this.shop_mul_sel.length <= 0) {
					this.$message({
						showClose: true,
						type: 'warning',
						message: '至少选择一个店铺'
					});
					return;
				}
				this.$confirm('确认执行此操作嘛？, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$message({
						type: 'success',
						message: '操作成功!'
					});
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消'
					});
				});
				console.log("sss")
			},
			onsearch() {
				this.type = 3;
				this.getshoplist();
			},
			shopSelectionChange(val) {
				this.shop_mul_sel = val;
				this.selshopnum = this.shop_mul_sel.length;
				console.log("this.multipleSelection", this.shop_mul_sel)
			},
			getshoplist() {
				const formData1 = new FormData()
				formData1.append('keywords', this.keywords1)
				formData1.append('type', this.type)
				formData1.append('act', "list")
				formData1.append('token', this.$Cookies.get("vue_elm_token"))
				const url = 'http://www.vssite.com/api/getShop.aspx'
				axios.post(url, formData1).then(data => {
					this.type = "";
					if (data.data.ret != 0) {
						console.log("sss")
					} else {
						this.shoplist = data.data.data;
					}
					console.log('data', data)
				}).catch(response => {
					console.log(response)
				})
			},
			quxiao() {
				this.$router.go(-1)
			}
		}
	}
</script>

<style>
	.first-tip {
		padding: 15px 0 20px 0;
	}
</style>
